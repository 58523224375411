<template>
  <div>
    <base-header class="pb-7 pt-md-6 bg-gradient-dark"></base-header>
    <div class="order-xl-1">
      <div class="card">
        <div class="card-header">
          <b-row align-v="center" slot="header" >
            <b-col cols="8">
              <h2 class="mb-0"><i class="fas fa-shopping-bag"></i> ข้อมูลสินค้า </h2>
            </b-col>
          </b-row>
        </div>

        <div class="card-body">
          <page-product/>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import pageProduct from './view/page.product';
  export default {
    methods: {},
    components: {
     pageProduct
    }
  };
</script>
<style>

</style>
